import { FC, Ref, forwardRef } from 'react';
import { useField, FieldConfig } from 'formik';

interface Props extends FieldConfig {
  namePrefix?: string;
  setValueProp?: string;
  setTouchedProp?: string;
  setErrorProp?: string;
}

const Field: FC<Props> = (
  {
    setValueProp,
    setTouchedProp,
    setErrorProp,
    namePrefix = '',
    name,
    as: Component = 'input',
    ...rest
  },
  forwardedRef: Ref
) => {
  const props = { name: `${namePrefix}${name}`, ...rest };

  const [field, meta, { setValue, setTouched, setError }] = useField(props);

  const componentProps = {
    meta,
    ...field,
    ...props,
    ...(setValueProp ? { [setValueProp]: setValue } : {}),
    ...(setTouchedProp ? { [setTouchedProp]: setTouched } : {}),
    ...(setErrorProp ? { [setErrorProp]: setError } : {}),
  };

  return <Component ref={forwardedRef} {...componentProps} />;
};

export default forwardRef(Field);
