import { FC, Ref, forwardRef } from 'react';
import classNames from 'classnames';

interface Props {
  label: string | HTMLElement;
  name: string;
  id: string;
  hideLabel?: boolean;
  labelLeft?: boolean;
  error?: boolean;
  errorMsg?: string;
}

const Checkbox: FC<Props> = (
  {
    label,
    name,
    id,
    hideLabel = false,
    error = false,
    errorMsg,
    labelLeft = false,
    ...props
  },
  forwardedRef: Ref
) => {
  const className = classNames('field', 'field--checkbox', {
    'field--has-error': !!error,
    'field--hidden-label': hideLabel,
  });

  return (
    <div className={className}>
      <div className="field__checkbox">
        <label htmlFor={id || name} className="field_label">
          {labelLeft === true && label}

          <input
            ref={forwardedRef}
            name={name}
            id={id || name}
            {...props}
            data-testid={`${name}Checkbox`}
            type="checkbox"
          />
          {labelLeft === false && label}
        </label>
      </div>
      {error && errorMsg && <span className="field__error">{errorMsg}</span>}
    </div>
  );
};

export default forwardRef(Checkbox);
