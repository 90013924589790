import { groupBy, uniq } from 'lodash';

import { formatDate, dateFormatForAPI } from '../utils/date';

const getBookingItemParam = ({
  startTime,
  endTime,
  space: { id: spaceId } = {},
}) => ({
  date: formatDate(startTime, dateFormatForAPI),
  startTime: formatDate(startTime, 'HH:mm'),
  endTime: formatDate(endTime, 'HH:mm'),
  spaceId,
});

const bookingToAvailabilityPayload = booking => {
  const groupedBookingItems = groupBy(
    booking.bookingItems,
    ({ startTime, endTime }) =>
      `${formatDate(startTime, 'HH:mm')}${formatDate(endTime, 'HH:mm')}`
  );
  const bookingSlots = Object.keys(groupedBookingItems).map(i => {
    const firstBookingItem = groupedBookingItems[i][0];

    return {
      startTime: formatDate(firstBookingItem.startTime, 'HH:mm'),
      endTime: formatDate(firstBookingItem.endTime, 'HH:mm'),
    };
  });
  const spaceIds = uniq(
    booking.bookingItems.map(bookingItem => bookingItem.space.id)
  );

  return {
    date: formatDate(booking.startTime, dateFormatForAPI),
    spaceIds,
    clientServiceId: booking.clientService.id,
    slots: bookingSlots,
    ...(booking.recurrenceRules ? { recurrence: booking.recurrenceRules } : {}),
    ...(booking.bookingItems
      ? { bookingItems: booking.bookingItems.map(getBookingItemParam) }
      : {}),
    excludedBookingIds: [booking.id],
  };
};

export default bookingToAvailabilityPayload;
