import { User } from 'hooks/useUsers';
import { USER_SERVICES, CLIENT_SERVICES } from 'constants/serviceLevels';
import { isPrimaryUser } from './roles';

type ServiceLevel = User['serviceLevel'];

export const isUserServiceLevel = (serviceLevel: ServiceLevel) =>
  serviceLevel === USER_SERVICES;

export const isClientServiceLevel = (serviceLevel: ServiceLevel) =>
  serviceLevel === CLIENT_SERVICES;

export const isEditable = (user: User, currentUser?: User) =>
  currentUser &&
  (user.id === currentUser.id || isPrimaryUser(currentUser.role.displayName));
