import { useState, createContext } from 'react';
import PropTypes from 'prop-types';

export const MobileHeaderContext = createContext({
  stickyNavVisible: false,
  toggleStickyNavVisibility: () => {},
  menuOpen: false,
  setMenuOpen: () => {},
  searchOpen: false,
  setSearchOpen: () => {},
  roomTypesOpen: false,
  setRoomTypesOpen: () => {},
});

const MobileHeaderProvider = ({ children }) => {
  const [stickyNavVisible, toggleStickyNavVisibility] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [roomTypesOpen, setRoomTypesOpen] = useState(false);

  return (
    <MobileHeaderContext.Provider
      value={{
        stickyNavVisible,
        toggleStickyNavVisibility,
        menuOpen,
        setMenuOpen,
        searchOpen,
        setSearchOpen,
        roomTypesOpen,
        setRoomTypesOpen,
      }}
    >
      {children}
    </MobileHeaderContext.Provider>
  );
};

MobileHeaderProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MobileHeaderProvider;
