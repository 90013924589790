import { useState, useRef, useEffect, useCallback } from 'react';

const tryParse = value => {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
};

const storageUndefined =
  typeof localStorage === 'undefined' || localStorage === null;

const readStorage = storageUndefined
  ? async () => null
  : async key => tryParse(localStorage.getItem(key));

const writeStorage = storageUndefined
  ? () => {}
  : (key, value) =>
      localStorage.setItem(
        key,
        typeof value === 'object' ? JSON.stringify(value) : `${value}`
      );

const removeStorage = storageUndefined
  ? () => {}
  : key => localStorage.removeItem(key);

const dispatchEvent = (key, newValue, storageArea, instance) =>
  window.dispatchEvent(
    new CustomEvent('sameContextStorage', {
      detail: { instance, storageArea, key, newValue },
    })
  );

const useLocalStorage = (key: string, defaultValue: any = null) => {
  const [{ value, initialising, received }, setState] = useState({
    value: defaultValue,
    initialising: true,
    received: null,
  });

  const defaultValueRef = useRef(defaultValue);
  const valueRef = useRef(value);

  useEffect(() => {
    const initialise = async () => {
      const storedValue = await readStorage(key);

      valueRef.current =
        storedValue === null ? defaultValueRef.current : storedValue;
      setState({
        value: storedValue === null ? defaultValueRef.current : storedValue,
        initialising: false,
        received: null,
      });
    };

    initialise();
  }, [key]);

  // The instance is just an arbitrary object whose identity is used to
  // determine whether the event was dispatched from this instance of the
  // hook or a different instance within the same browser tab.
  const instanceRef = useRef({});

  useEffect(() => {
    const onStorage = ({ storageArea, key: eventKey, newValue }) => {
      if (storageArea === localStorage && eventKey === key) {
        const nextValue = tryParse(newValue);

        if (nextValue === null) {
          return;
        }

        valueRef.current = nextValue;

        setState({
          value: nextValue,
          initialising: false,
          received: true,
        });
      }
    };

    window.addEventListener('storage', onStorage);
    return () => {
      window.removeEventListener('storage', onStorage);
    };
  }, [key]);

  useEffect(() => {
    const onSameContextStorage = ({
      detail: { instance, storageArea, key: eventKey, newValue: nextValue },
    }) => {
      if (storageArea === localStorage && eventKey === key) {
        valueRef.current =
          nextValue === null ? defaultValueRef.current : nextValue;
        setState({
          value: nextValue === null ? defaultValueRef.current : nextValue,
          initialising: false,
          // All instances are equal but some are more equal than others.
          received: instance !== instanceRef.current,
        });
      }
    };

    window.addEventListener('sameContextStorage', onSameContextStorage);
    return () => {
      window.removeEventListener('sameContextStorage', onSameContextStorage);
    };
  }, [key]);

  const setValue = useCallback(
    arg => {
      const nextValue = arg instanceof Function ? arg(valueRef.current) : arg;
      writeStorage(key, nextValue);
      dispatchEvent(key, nextValue, localStorage, instanceRef.current);
    },
    [key]
  );

  const removeValue = useCallback(() => {
    removeStorage(key);
    dispatchEvent(key, null, localStorage, instanceRef.current);
  }, [key]);

  return [value, setValue, removeValue, initialising, received];
};

export default useLocalStorage;
