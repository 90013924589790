import {
  createContext,
  useContext,
  useReducer,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import queryString from 'query-string';
import getConfig from 'next/config';
import { useCookies } from 'react-cookie';
import { useRouter } from 'next/router';

import { i18n as I18n } from 'utils/i18n';
import { renderBookingsToGTM } from 'utils/gtm';
import reducer, { initialState } from './reducer';

import { AuthContext } from '../auth';
import bookingToAvailabilityPayload from '../../services/bookingToAvailabilityPayload';

import { useApiRequest } from '../../hooks/useApiRequest';
import useDataLayer from '../../hooks/useDataLayer';

export const BasketContext = createContext({
  ...initialState,
  actions: {
    fetchBasket: async () => {},
    fetchPaymentMethods: async () => {},
    checkAvailability: () => {},
    submitBookingToBasket: async () => false,
    removeBooking: async () => {},
    removeBookingItems: async () => {},
    setPayAsYouGo: () => {},
    setPayInFull: () => {},
    submitBookings: async () => {},
    updateBooking: async () => {},
    refreshBookings: () => {},
  },
});

const BasketProvider = ({ children }) => {
  const { loggedIn } = useContext(AuthContext);
  const [cookies, setCookie, removeCookie] = useCookies();
  const router = useRouter();

  const apiRequest = useApiRequest();

  const [
    {
      loading,
      totals,
      items = [],
      hasPayAsYouGo,
      clashes,
      registeredPaymentSources,
      servicesMissingPaymentMethods,
      paymentMethodAllocations,
      confirmed,
      confirmedBookingItems,
      submitting,
    },
    dispatch,
  ] = useReducer(reducer, {
    ...initialState,
  });

  const { dismissedAt = null } = cookies.basketExpiry || {};

  const fetchBasket = useCallback(
    async (refresh = false) => {
      dispatch({
        type: 'FETCH_BASKET',
      });

      const {
        data: { bookings, expiresAt: nextExpiresAt, totals: apiTotals },
      } = await apiRequest.get(`/v1/bookings/tentative?refresh=${refresh}`);

      if (bookings.length > 0 && nextExpiresAt) {
        setCookie(
          'basketExpiry',
          {
            expiresAt: nextExpiresAt,
            dismissedAt,
          },
          { path: '/', secure: true }
        );
      }

      if (bookings.length === 0) {
        removeCookie('basketExpiry');
      }

      dispatch({
        type: 'FETCH_BASKET_SUCCESS',
        payload: {
          items: bookings,
          totals: apiTotals,
          hasPayAsYouGo: apiTotals.hasPayAsYouGo,
        },
      });
    },
    [dismissedAt, setCookie, removeCookie, apiRequest]
  );

  const fetchPaymentMethods = useCallback(async () => {
    dispatch({
      type: 'FETCH_PAYMENT_METHODS',
    });

    const {
      data: { registeredPaymentSources: apiRegisteredPaymentSources },
    } = await apiRequest.get('/v1/registered_payment_sources');

    dispatch({
      type: 'FETCH_PAYMENT_METHODS_SUCCESS',
      payload: {
        registeredPaymentSources: apiRegisteredPaymentSources,
      },
    });
  }, [apiRequest]);

  const checkAvailability = useCallback(() => {
    items.forEach(async booking => {
      const {
        data: { slots },
      } = await apiRequest.post(
        `/v1/spaces/availability`,
        bookingToAvailabilityPayload({ ...booking, recurrenceRules: undefined })
      );

      dispatch({
        type: 'SET_EXPIRED_ITEM_CLASHES',
        payload: {
          id: booking.id,
          clashes: slots.some(slot => slot.conflicts),
        },
      });
    });
  }, [items, apiRequest]);

  const pushDataLayer = useDataLayer();

  const submitBookingToBasket = useCallback(
    bookingData =>
      apiRequest
        .post('/v1/bookings', {
          booking: bookingData,
        })
        .then(({ data: { bookings } }) => {
          pushDataLayer({
            event: 'addToCart',
            ecommerce: {
              add: {
                products: renderBookingsToGTM(bookings),
              },
            },
          });

          setTimeout(() => {
            fetchBasket();
          }, 500);
        })
        .then(() =>
          dispatch({
            type: 'ADDED_TO_BASKET',
          })
        )
        .catch(error =>
          Promise.resolve(
            dispatch({
              type: 'ADD_TO_BASKET_ERROR',
            })
          ).then(() => Promise.reject(error))
        ),
    [pushDataLayer, fetchBasket, apiRequest]
  );

  const removeBooking = useCallback(
    async id => {
      await apiRequest.delete(`/v1/bookings/${id}`);

      fetchBasket();
    },
    [fetchBasket, apiRequest]
  );

  const removeBookingItems = useCallback(
    async (bookingId, ids) => {
      await apiRequest.delete(`/v1/bookings/${bookingId}/booking_items`, {
        params: { ids },
      });

      fetchBasket();
    },
    [fetchBasket, apiRequest]
  );

  const setPayAsYouGo = useCallback(() => {
    setCookie('payInFull', false, { path: '/', secure: true });
  }, [setCookie]);

  const setPayInFull = useCallback(() => {
    setCookie('payInFull', true, { path: '/', secure: true });
  }, [setCookie]);

  const payInFull = useMemo(
    () => (cookies.payInFull && cookies.payInFull === 'true') || !hasPayAsYouGo,
    [cookies.payInFull, hasPayAsYouGo]
  );

  const submitBookings = useCallback(async () => {
    dispatch({
      type: 'SUBMIT_BOOKINGS',
    });

    // If payment methods are not available for all bookings then a one-off
    // payment will be required and the user will need to go to Worldpay
    // to make payment.
    //
    // When this happens `paymentRequired` will be true and the `redirect` will be an
    // external URL
    //
    // If payment is still required for some bookings the user with be redirect
    // to Worldpay to make a manual payment for those items
    //
    const { data: submissionResponse } = await apiRequest.post(
      '/v1/bookings/submit',
      {
        bookings: items.map(item => {
          const allocatedPaymentMethod = paymentMethodAllocations[item.id];

          return {
            id: item.id,
            registeredPaymentSourceId:
              allocatedPaymentMethod && !item.offlinePayments
                ? allocatedPaymentMethod.id
                : null,
          };
        }),
        payInFull,
      }
    );

    removeCookie('payInFull');

    const redirects = (() => {
      const redirectParams = queryString.stringify(
        {
          bookingIds: items.map(item => item.id),
          reference: submissionResponse.reference,
          payAsYouGo: !payInFull,
          incTotals: true,
        },
        { arrayFormat: 'bracket', skipNull: true }
      );
      const successRedirectPath = `${I18n.t(
        'navigation.basket_processing.url'
      )}?${redirectParams}`;
      const errorRedirectPath = `${I18n.t(
        'navigation.basket.url'
      )}?error=payment_error`;
      const basketPath = I18n.t('navigation.basket.url');

      return {
        success: successRedirectPath,
        error: errorRedirectPath,
        cancel: basketPath,
        failure: errorRedirectPath,
      };
    })();

    const { publicRuntimeConfig } = getConfig();

    if (
      submissionResponse.paymentRequired &&
      publicRuntimeConfig.hostedPayments === 'false'
    ) {
      router.push(
        {
          pathname: '/payments',
          query: {
            returnType: 'basket',
            description: 'Invoice Payment',
            amount: submissionResponse.outstandingAmount,
            reference: submissionResponse.reference,
            bookingItemIds: submissionResponse.bookingItemIds,
            organisationId: submissionResponse.organisationId,
            successUrl: redirects.success,
            errorUrl: redirects.error,
            cancelUrl: redirects.cancel,
            failureUrl: redirects.failure,
            type: 'payment',
          },
        },
        '/payments'
      );
    } else if (submissionResponse.paymentRequired) {
      const { data: paymentResponse } = await apiRequest.post('/v1/payments', {
        amount: submissionResponse.outstandingAmount,
        reference: submissionResponse.reference,
        bookingItemIds: submissionResponse.bookingItemIds,
        organisationId: submissionResponse.organisationId,
        successUrl: redirects.success,
        errorUrl: redirects.error,
        cancelUrl: redirects.cancel,
        failureUrl: redirects.failure,
      });

      router.push(paymentResponse.redirect);
    } else {
      router.push(redirects.success);
    }

    dispatch({
      type: 'SUBMIT_BOOKINGS_SUCCESS',
    });
  }, [
    removeCookie,
    items,
    paymentMethodAllocations,
    payInFull,
    router,
    apiRequest,
  ]);

  const updateBooking = useCallback(
    async (booking, updatedParams) => {
      await apiRequest.put(`/v1/bookings/${booking.id}`, {
        booking: { ...updatedParams },
      });

      fetchBasket();
    },
    [fetchBasket, apiRequest]
  );

  const refreshBookings = useCallback(() => fetchBasket(true), [fetchBasket]);

  const actionMethods = {
    fetchBasket,
    fetchPaymentMethods,
    checkAvailability,
    submitBookingToBasket,
    removeBooking,
    removeBookingItems,
    setPayAsYouGo,
    setPayInFull,
    submitBookings,
    updateBooking,
    refreshBookings,
  };

  useEffect(() => {
    if (loggedIn) {
      fetchBasket(false);
    }
  }, [fetchBasket, loggedIn]);

  useEffect(() => {
    dispatch({
      type: 'CHECK_SERVICES_MISSING_PAYMENT_METHODS',
    });

    dispatch({
      type: 'ALLOCATE_PAYMENT_METHODS_TO_BOOKINGS',
    });
  }, [registeredPaymentSources]);

  return (
    <BasketContext.Provider
      value={{
        loading,
        totals,
        items,
        hasPayAsYouGo,
        payInFull,
        clashes,
        registeredPaymentSources,
        servicesMissingPaymentMethods,
        paymentMethodAllocations,
        confirmed,
        confirmedBookingItems,
        actions: actionMethods,
        submitting,
      }}
    >
      {children}
    </BasketContext.Provider>
  );
};

export default BasketProvider;
