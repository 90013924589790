import { paymentMethodForBooking } from 'utils/bookings';

interface State {
  items: Array<any>;
  totals: {
    hasPayAsYouGo: boolean;
    payLater: {
      netCost: number;
      grossCost: number;
      vatAmount: number;
      grossCostOnlinePayAsYouGo: number;
      grossCostOfflinePayAsYouGo: number;
    };
    payNow: {
      netCost: number;
      grossCost: number;
      vatAmount: number;
      grossCostOnline: number;
      grossCostOffline: number;
    };
  };
  hasPayAsYouGo: boolean;
  bookingsExpireAt: Date;
  loading: boolean;
  submitting: boolean;
  error: boolean;
  clashes: any;
  registeredPaymentSources: any;
  servicesMissingPaymentMethods: Array<string>;
  paymentMethodAllocations: Array<any>;
  confirmed: boolean;
  confirmedBookingItems: Array<any>;
}

export type Action =
  | { type: 'FETCH_BASKET' }
  | { type: 'FETCH_BASKET_SUCCESS'; payload: any }
  | { type: 'ADD_TO_BASKET' }
  | { type: 'ADDED_TO_BASKET' }
  | { type: 'ADD_TO_BASKET_ERROR' }
  | { type: 'SET_EXPIRED_ITEM_CLASHES'; payload: any }
  | { type: 'FETCH_PAYMENT_METHODS' }
  | { type: 'FETCH_PAYMENT_METHODS_SUCCESS'; payload: any }
  | { type: 'CHECK_SERVICES_MISSING_PAYMENT_METHODS' }
  | { type: 'ALLOCATE_PAYMENT_METHODS_TO_BOOKINGS' }
  | { type: 'PAY_AS_YOU_GO' }
  | { type: 'PAY_IN_FULL' }
  | { type: 'SUBMIT_BOOKINGS' }
  | { type: 'SUBMIT_BOOKINGS_SUCCESS' }
  | { type: 'BASKET_CONFIRMED' };

export const initialState: State = {
  items: [],
  totals: {
    hasPayAsYouGo: true,
    payLater: {
      netCost: 10,
      grossCost: 12,
      vatAmount: 2,
    },
    payNow: {
      netCost: 10,
      grossCost: 12,
      vatAmount: 2,
    },
  },
  bookingsExpireAt: new Date(),
  hasPayAsYouGo: false,
  loading: false,
  submitting: false,
  error: false,
  clashes: [],
  registeredPaymentSources: [],
  servicesMissingPaymentMethods: [],
  paymentMethodAllocations: [],
  confirmed: false,
  confirmedBookingItems: [],
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'ADD_TO_BASKET': {
      return { ...state, submitting: true, error: false };
    }

    case 'ADDED_TO_BASKET': {
      return {
        ...state,
        submitting: false,
      };
    }

    case 'ADD_TO_BASKET_ERROR': {
      return {
        ...state,
        submitting: false,
        error: true,
      };
    }

    case 'FETCH_BASKET': {
      return { ...state, loading: true };
    }

    case 'FETCH_BASKET_SUCCESS': {
      return {
        ...state,
        loading: false,
        items: action.payload.items,
        totals: action.payload.totals,
        hasPayAsYouGo: action.payload.hasPayAsYouGo,
        bookingsExpireAt: action.payload.expiresAt,
        clashes: [],
      };
    }

    case 'SET_EXPIRED_ITEM_CLASHES': {
      return {
        ...state,
        loading: false,
        clashes: [...state.clashes, action.payload],
      };
    }

    case 'FETCH_PAYMENT_METHODS_SUCCESS': {
      return {
        ...state,
        registeredPaymentSources: action.payload.registeredPaymentSources,
      };
    }

    case 'CHECK_SERVICES_MISSING_PAYMENT_METHODS': {
      const overallCost = state.totals.payNow.grossCost;
      const servicesMissingPaymentMethods =
        overallCost === 0
          ? []
          : state.items
              .reduce((acc, booking) => {
                const foundPaymentMethod = paymentMethodForBooking(
                  state.registeredPaymentSources,
                  booking
                );

                if (
                  !foundPaymentMethod &&
                  !acc.includes(booking.clientService.name) &&
                  !booking.offlinePayments
                ) {
                  acc.push(booking.clientService.name);
                }

                return acc;
              }, [])
              .sort((a: string, b: string) => a.localeCompare(b));

      return {
        ...state,
        servicesMissingPaymentMethods,
      };
    }

    case 'ALLOCATE_PAYMENT_METHODS_TO_BOOKINGS': {
      const overallCost = state.totals.payNow.grossCost;
      const paymentMethodAllocations =
        overallCost === 0
          ? {}
          : state.items.reduce((acc, booking) => {
              const paymentMethod = paymentMethodForBooking(
                state.registeredPaymentSources,
                booking
              );

              if (paymentMethod) {
                acc[booking.id] = paymentMethod;
              }

              return acc;
            }, {});

      return {
        ...state,
        paymentMethodAllocations,
      };
    }

    case 'SUBMIT_BOOKINGS': {
      return {
        ...state,
        submitting: true,
      };
    }

    case 'SUBMIT_BOOKINGS_SUCCESS': {
      return {
        ...state,
        submitting: false,
        items: [],
        totals: initialState.totals,
      };
    }

    default:
      return state;
  }
};

export default reducer;
