import moment from 'moment';
import { formatStartAndEndTimes } from './time';

export const renderBookingsToGTM = bookings => {
  return bookings.flatMap(booking => {
    const bookingId = booking.id;
    const clientServiceName = booking.clientService.name;
    const venueName = booking.venue.name;
    const isRecurring = booking.recurring;

    return booking.bookingItems.map(bookingItem => {
      const quantity = moment
        .duration(
          moment(bookingItem.endTime).diff(moment(bookingItem.startTime))
        )
        .asHours();

      return {
        id: bookingItem.id,
        name: `${venueName} - ${bookingItem.space.name}`,
        sku: bookingItem.space.id,
        price: bookingItem.totalCost / quantity,
        quantity,
        dimension1: bookingId,
        dimension2: clientServiceName,
        dimension3: isRecurring,
        metric1: formatStartAndEndTimes(
          bookingItem.startTime,
          bookingItem.endTime
        ),
      };
    });
  });
};
