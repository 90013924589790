import moment from 'moment';

export const formatStartAndEndTimes = (
  start: string,
  end: string,
  parseFormat: string = null,
  displayFormat: string = 'HH:mm'
): string => {
  const format = (string: string) =>
    moment(string, parseFormat).format(displayFormat);

  const formattedStart = format(start);
  const formattedEnd = format(end);

  return `${formattedStart} - ${formattedEnd}`;
};
