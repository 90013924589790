import { groupBy } from 'lodash';
import moment from 'moment';

import { BookingItem } from 'hooks/useBookingItems';

import { CLIENT_SERVICES } from '../constants/serviceLevels';
import {
  BILLED_OR_PAID_STATEMENT_STATES,
  PAYABLE_STATEMENT_STATES,
} from '../constants/bookings';
import { sortByTime } from './date';
import { isBooker, notBooker } from './roles';
import { isClientServiceLevel } from './users';

type ServiceLevel = 'user' | 'client';

interface User {
  role: string;
  clientServiceLevel: ServiceLevel;
  bookableServices: number[];
}

interface ClientService {
  id: number;
}

interface Booking {
  clientService: ClientService;
}

// A booking is payable when:
// - A booking falls within the current month or fell in previous months
// - And an invoice has been raised for the booking
// - And the booking invoice has not yet been fully paid
export const itemIsPayable = item =>
  moment(item.startTime).isSameOrBefore(moment().endOf('month')) &&
  !!item.statementDueDate &&
  PAYABLE_STATEMENT_STATES.includes(item.statementState) &&
  item.paymentState !== 'paid';

export const isPayableBooking = (booking, user) => {
  const userCanPayForBookings =
    notBooker(user.role) ||
    (isBooker(user.role) &&
      booking.clientService &&
      user.bookableServices.includes(booking.clientService.id));

  const bookingIsPayable = booking.bookingItems.some(itemIsPayable);

  return userCanPayForBookings && bookingIsPayable;
};

export const earliestDueInvoice = booking => {
  const reducer = (earliestItem, item) => {
    if (!itemIsPayable(item)) return earliestItem;

    return !earliestItem ||
      moment(item.startTime).isBefore(earliestItem.startTime)
      ? item
      : earliestItem;
  };

  const earliestPayableBookingItem = booking.bookingItems.reduce(reducer, null);

  if (earliestPayableBookingItem) {
    return earliestPayableBookingItem.statementId;
  }

  return null;
};

export const isReadOnlyBooking = (booking: Booking, user: User) => {
  if (notBooker(user.role)) {
    return false;
  }

  if (isClientServiceLevel(user.clientServiceLevel)) {
    return false;
  }

  return !(
    isBooker(user.role) &&
    booking.clientService &&
    user.bookableServices.includes(booking.clientService.id)
  );
};

export const filterInitialOccuringBookingItems = bookingItems => {
  const groupedBookingItems = groupBy(bookingItems, bookingItem => {
    const startHour = moment(bookingItem.startTime).hour();
    const endHour = moment(bookingItem.endTime).hour();

    return `${startHour}${endHour}${bookingItem.space.id}`;
  });

  return Object.keys(groupedBookingItems)
    .map(groupedBookingItemKey => {
      return groupedBookingItems[groupedBookingItemKey].sort(
        sortByTime('startTime')
      )[0];
    })
    .sort(sortByTime('startTime'));
};

export const paymentMethodForBooking = (paymentMethods, booking) => {
  const candidates = paymentMethods
    .sort(a => (a.serviceLevel !== CLIENT_SERVICES ? -1 : 1))
    .filter(
      paymentMethod =>
        !booking.offlinePayments &&
        (paymentMethod.clientServiceIds.includes(booking.clientService.id) ||
          paymentMethod.serviceLevel === CLIENT_SERVICES)
    );

  const active = candidates.filter(
    paymentMethod => paymentMethod.state === 'active'
  );

  const pending = candidates.filter(
    paymentMethod => paymentMethod.state === 'pending'
  );

  return active.length > 0 ? active[0] : pending[0];
};

export const allBookingsBilledOrPaid = (items: BookingItem[]) =>
  items.every(item =>
    BILLED_OR_PAID_STATEMENT_STATES.includes(item.statementState)
  );
