export const TABLET_WIDTH = 800;
export const MOBILE_WIDTH = 568;
export const DESKTOP_ROOM_TYPE_WIDTH = 184;
export const NARROW = 500;
export const SMALL_TABLET_WIDTH = 768;

export const HEADER_OFFSET = 55;

export const MOBILE_TOOLTIP_DISTANCE = 15;
export const DESKTOP_TOOLTIP_DISTANCE = 15;
export const SHARED_TOOLTIP_DISTANCE = 15;
