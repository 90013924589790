import { createContext, useReducer, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import reducer, { initialState, actions } from './reducer';
import useLocalStorage from '../../hooks/useLocalStorage';

export const SearchContext = createContext({
  ...initialState,
  actions: {
    updateDistance: () => {},
    updateCapacity: () => {},
    updateFacets: () => {},
    updateDate: () => {},
    updateProperty: () => {},
    updateRoomTypes: () => {},
    updateLocation: () => {},
    updateLatLng: () => {},
    onSetSort: () => {},
    onClearRoomTypes: () => {},
    clearAllFilters: () => {},
    resetSearch: () => {},
    updatePreviousSearches: () => {},
  },
});

const SearchProvider = ({ children }) => {
  const [
    {
      filters,
      results,
      alternativeVenues,
      aggregates,
      totalVenues,
      totalSpaces,
      currentView,
      loading,
      previousSearches,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  const [storagePreviousSearches, setStoragePreviousSearches] = useLocalStorage(
    'previousSearches',
    []
  );

  useEffect(() => {
    dispatch({
      type: actions.SET_PREVIOUS_SEARCHES,
      payload: storagePreviousSearches,
    });
  }, [storagePreviousSearches]);

  const updateDistance = useCallback(
    distance => dispatch({ type: actions.UPDATE_DISTANCE, payload: distance }),
    []
  );

  const updateCapacity = useCallback(
    capacity => dispatch({ type: actions.UPDATE_CAPACITY, payload: capacity }),
    []
  );

  const updateFacets = useCallback(
    facets => dispatch({ type: actions.UPDATE_FACETS, payload: facets }),
    []
  );

  const updateDate = useCallback(
    date => dispatch({ type: actions.UPDATE_DATE_AVAILABLE, payload: date }),
    []
  );

  const updateProperty = useCallback(
    property => dispatch({ type: actions.UPDATE_PROPERTY, payload: property }),
    []
  );

  const updateRoomTypes = useCallback(
    roomTypes =>
      dispatch({ type: actions.UPDATE_ROOM_TYPES, payload: roomTypes }),
    []
  );

  const clearAllFilters = useCallback(() => {
    dispatch({ type: actions.CLEAR_ALL_FILTERS });
  }, []);

  const resetSearch = useCallback(() => {
    dispatch({ type: actions.RESET_SEARCH });
  }, []);

  const updateLocation = useCallback(location => {
    dispatch({ type: actions.SET_LOCATION, payload: location });
  }, []);

  const updateLatLng = useCallback(
    latLng => {
      dispatch({ type: actions.SET_LATLNG, payload: latLng });
      if (latLng) updateDistance(10);
    },
    [updateDistance]
  );

  const onSetSort = useCallback(sort => {
    dispatch({ type: actions.SET_SORT, payload: sort });
  }, []);

  const onClearRoomTypes = useCallback(() => {
    dispatch({ type: actions.CLEAR_ROOM_TYPES });
  }, []);

  const applyPreviousSearch = useCallback(
    options => {
      const { location, property, distance, capacity, facets = [] } = options;

      clearAllFilters();

      if (location) {
        updateLocation(location);
      }

      if (property) {
        updateProperty(property);
      }

      updateDistance(distance);

      if (capacity) {
        updateCapacity(capacity);
      }

      if (facets) {
        updateFacets(facets);
      }
    },
    [
      clearAllFilters,
      updateLocation,
      updateProperty,
      updateDistance,
      updateCapacity,
      updateFacets,
    ]
  );

  const setQuickSearch = useCallback(options => {
    dispatch({ type: actions.SET_QUICK_SEARCH, payload: options });
  }, []);

  const updatePreviousSearches = useCallback(
    filters => {
      const {
        location,
        property = [],
        distance,
        capacity,
        facets = [],
      } = filters;

      if (
        !!location ||
        !!distance ||
        !!capacity ||
        property.length > 0 ||
        facets.length > 1
      ) {
        const optionsJson = JSON.stringify(filters);
        if (
          !storagePreviousSearches.some(f => JSON.stringify(f) === optionsJson)
        ) {
          const updatedPreviousSearches = [
            filters,
            ...storagePreviousSearches,
          ].slice(0, 5);
          setStoragePreviousSearches(updatedPreviousSearches);
          dispatch({
            type: actions.SET_PREVIOUS_SEARCHES,
            payload: updatedPreviousSearches,
          });
        }
      }
    },
    [setStoragePreviousSearches, storagePreviousSearches]
  );

  const actionsMethods = {
    updateDistance,
    updateCapacity,
    updateFacets,
    updateDate,
    updateProperty,
    updateRoomTypes,
    updateLocation,
    updateLatLng,
    onSetSort,
    onClearRoomTypes,
    clearAllFilters,
    resetSearch,
    applyPreviousSearch,
    setQuickSearch,
    updatePreviousSearches,
  };

  return (
    <SearchContext.Provider
      value={{
        filters,
        results,
        alternativeVenues,
        aggregates,
        totalVenues,
        totalSpaces,
        currentView,
        loadingResults: loading,
        actions: actionsMethods,
        previousSearches,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

SearchProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SearchProvider;
