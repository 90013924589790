export const MAXIMUM_SPACES_PER_BOOKING = 5;
export const CCG_BOOKING_RESTRICTION = 21;
export const NON_CCG_BOOKING_RESTRICTION = 15;
export const MAX_TRANSACTION_VALUE = 20000;
export const MAX_WORLDPAY_TRANSACTION_VALUE = 20000;
export const MAX_GOCARDLESS_TRANSACTION_VALUE = 25000;
export const BILLED_OR_PAID_STATEMENT_STATES = [
  'billed',
  'part_paid',
  'fully_paid',
] as const;
export const PAYABLE_STATEMENT_STATES = ['billed', 'part_paid'] as const;
export const NON_PAYABLE_STATEMENT_STATES = [
  'void',
  'draft',
  'fully_paid',
] as const;

export const TENTATIVE = 'tentative';
export const EXPIRED = 'expired';
export const PROVISIONAL = 'provisional';
export const CONFIRMED = 'confirmed';
export const CANCELLED = 'cancelled';

export const BOOKING_STATES = {
  tentative: TENTATIVE,
  expired: EXPIRED,
  provisional: PROVISIONAL,
  confirmed: CONFIRMED,
} as const;

export type BookingState = typeof BOOKING_STATES[keyof typeof BOOKING_STATES];
