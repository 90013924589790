import { i18n as I18n } from '../utils/i18n';

export const roomTypes = [
  {
    machineName: 'minorOperation',
    label: 'Minor operations',
    category: 'Minor operation suite',
    clinical: true,
  },
  {
    machineName: 'treatment',
    label: 'Treatment',
    category: 'Treatment room',
    clinical: true,
  },
  {
    machineName: 'examination',
    label: 'Examination room',
    category: 'Examination room',
    clinical: true,
  },
  {
    machineName: 'consulting',
    label: 'Consulting',
    category: 'Consulting room',
    clinical: true,
  },
  {
    machineName: 'counselling',
    label: 'Counselling',
    category: 'Counselling/interview room',
    clinical: false,
  },
  {
    machineName: 'groupRoom',
    label: 'Group room',
    category: 'Group room',
    clinical: false,
  },
  {
    machineName: 'meetings',
    label: 'Meetings',
    category: 'Meeting room',
    clinical: false,
  },
  {
    machineName: 'offices',
    label: 'Offices',
    category: 'Office',
    clinical: false,
  },
] as const;

export const companyTypes = [
  { value: 'ALB', label: 'ALB' },
  { value: 'CCG', label: 'CCG' },
  { value: 'Charity', label: 'Charity' },
  { value: 'Commercial', label: 'Commercial' },
  { value: 'Commercial NHS Provider', label: 'Commercial NHS Provider' },
  { value: 'Community', label: 'Community' },
  { value: 'Dental', label: 'Dental' },
  { value: 'DHSC', label: 'DHSC' },
  { value: 'Education', label: 'Education' },
  {
    value: 'Emergency services (non-healthcare)',
    label: 'Emergency services (non-healthcare)',
  },
  { value: 'GP', label: 'GP' },
  { value: 'Local Government', label: 'Local Government' },
  { value: 'NHS England', label: 'NHS England' },
  { value: 'NHS Other', label: 'NHS Other' },
  { value: 'NHS Property Services', label: 'NHS Property Services' },
  { value: 'NHS Provider', label: 'NHS Provider' },
  { value: 'Other', label: 'Other' },
  { value: 'Private', label: 'Private' },
  { value: 'Private Individual', label: 'Private Individual' },
];

export const serviceTypes = [
  { value: 'Clinical', label: 'Clinical', isClinical: true },
  { value: 'Non-Clinical', label: 'Non-Clinical', isClinical: false },
];

export const SERVICE_IDENTIFIER_MAX_LENGTH = 30;

export const RESULTS_PER_PAGE = 5;

type AvailabilityFlag =
  | 'AVAILABLE'
  | 'UNAVAILABLE'
  | 'TIME_CLASH'
  | 'SERVICE_CLASH'
  | 'UNKNOWN';

export interface AvailabilityStatus {
  flag: AvailabilityFlag;
  label: string;
  slotClasses: string[];
  iconClasses: string[];
}

interface AvailabilityStatuses {
  [key: AvailabilityFlag]: AvailabilityStatus;
}

export const AVAILABILITY_STATUSES: AvailabilityStatuses = {
  AVAILABLE: {
    flag: 'AVAILABLE',
    label: I18n.t('availability.available'),
    slotClasses: ['available'],
    iconClasses: ['fa-check-circle'],
  },
  UNAVAILABLE: {
    flag: 'UNAVAILABLE',
    label: I18n.t('availability.unavailable'),
    slotClasses: ['unavailable'],
    iconClasses: [],
  },
  TIME_CLASH: {
    flag: 'TIME_CLASH',
    label: I18n.t('availability.time_clash'),
    slotClasses: ['time-clash'],
    iconClasses: ['fa-exclamation-triangle'],
  },
  SERVICE_CLASH: {
    flag: 'SERVICE_CLASH',
    label: I18n.t('availability.service_clash'),
    slotClasses: ['service-clash'],
    iconClasses: ['fa-exclamation-triangle'],
  },
  UNKNOWN: {
    flag: 'UNKNOWN',
    label: 'Loading',
    slotClasses: ['unavailable'],
    iconClasses: ['fa-spinner', 'spinning'],
  },
  PROVISIONAL: {
    flag: 'PROVISIONAL',
    label: I18n.t('availability.provisional'),
    slotClasses: ['available'],
    iconClasses: [],
  },
};

export const cancellationReasons = [
  { value: 'Booking no longer required', label: 'Booking no longer required' },
  { value: 'Change of details', label: 'Change of details' },
  {
    value: 'Cancelling to make another booking',
    label: 'Cancelling to make another booking',
  },
  { value: 'Other', label: 'Other' },
];
