import moment from 'moment';

import { dateFormatForAPI } from 'utils/date';

export const actions = {
  INITIALISE_ROOM_TYPES: 'INITIALISE_ROOM_TYPES',
  UPDATE_ROOM_TYPES: 'UPDATE_ROOM_TYPES',
  INITIALISE_VENUE_IDS: 'INITIALISE_VENUE_IDS',
  UPDATE_DISTANCE: 'UPDATE_DISTANCE',
  UPDATE_CAPACITY: 'UPDATE_CAPACITY',
  UPDATE_FACETS: 'UPDATE_FACETS',
  UPDATE_DATE_AVAILABLE: 'UPDATE_DATE_AVAILABLE',
  UPDATE_PROPERTY: 'UPDATE_PROPERTY',
  SET_LOCATION: 'SET_LOCATION',
  SET_LATLNG: 'SET_LATLNG',
  SET_SORT: 'SET_SORT',
  CLEAR_ROOM_TYPES: 'CLEAR_ROOM_TYPES',
  CLEAR_VENUE_IDS: 'CLEAR_VENUE_IDS',
  CLEAR_ALL_FILTERS: 'CLEAR_ALL_FILTERS',
  RESET_SEARCH: 'RESET_SEARCH',
  SET_QUICK_SEARCH: 'SET_QUICK_SEARCH',
  SET_PREVIOUS_SEARCHES: 'SET_PREVIOUS_SEARCHES',
};

export const initialState = {
  filters: {
    roomTypes: [],
    facets: [],
    capacity: null,
    distance: null,
    date: null,
    property: [],
    sort: 'Name',
    location: '',
    latLng: null,
    q: '',
  },
  previousSearches: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.INITIALISE_ROOM_TYPES: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    }

    case actions.UPDATE_CAPACITY: {
      return {
        ...state,
        filters: {
          ...state.filters,
          capacity: action.payload,
        },
      };
    }

    case actions.UPDATE_DATE_AVAILABLE: {
      return {
        ...state,
        filters: {
          ...state.filters,
          date: action.payload
            ? moment(action.payload).format(dateFormatForAPI)
            : null,
        },
      };
    }

    case actions.UPDATE_DISTANCE: {
      return {
        ...state,
        filters: {
          ...state.filters,
          distance: action.payload,
        },
      };
    }

    case actions.UPDATE_FACETS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          facets: action.payload,
        },
      };
    }

    case actions.UPDATE_PROPERTY: {
      return {
        ...state,
        filters: {
          ...state.filters,
          property: action.payload,
        },
      };
    }

    case actions.UPDATE_ROOM_TYPES: {
      return {
        ...state,
        filters: {
          ...state.filters,
          roomTypes: action.payload,
        },
      };
    }

    case actions.INITIALISE_VENUE_IDS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    }

    case actions.SET_LOCATION: {
      return {
        ...state,
        filters: {
          ...state.filters,
          location: action.payload,
          sort: 'Distance',
          facets: [],
          capacity: null,
          distance: null,
          property: [],
          date: null,
        },
      };
    }

    case actions.SET_LATLNG: {
      return {
        ...state,
        filters: {
          ...state.filters,
          latLng: action.payload,
        },
      };
    }

    case actions.SET_SORT: {
      return {
        ...state,
        filters: {
          ...state.filters,
          sort: action.payload,
        },
      };
    }

    case actions.CLEAR_ROOM_TYPES: {
      return {
        ...state,
        filters: {
          ...state.filters,
          roomTypes: [],
        },
      };
    }

    case actions.CLEAR_VENUE_IDS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          venueIds: [],
        },
      };
    }

    case actions.RESET_SEARCH: {
      return {
        ...state,
        filters: initialState.filters,
      };
    }

    case actions.CLEAR_ALL_FILTERS: {
      const { location, latLng } = state.filters;
      return {
        ...state,
        filters: {
          ...initialState.filters,
          location,
          latLng,
        },
      };
    }

    case actions.SET_QUICK_SEARCH: {
      const { location, latLng, distance, roomTypes } = action.payload;
      return {
        ...state,
        filters: {
          ...initialState.filters,
          location,
          sort: location ? 'Distance' : 'Name',
          latLng,
          distance,
          roomTypes,
        },
      };
    }

    case actions.SET_PREVIOUS_SEARCHES: {
      return {
        ...state,
        previousSearches: action.payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
