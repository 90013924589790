import { useCallback, useEffect, useMemo } from 'react';
import getConfig from 'next/config';
import TagManager from 'react-gtm-module';

const { publicRuntimeConfig } = getConfig();

const useDataLayer = dataLayer => {
  const valid = !!dataLayer;
  const dataLayerJson = useMemo(() => JSON.stringify(dataLayer), [dataLayer]);

  const pushDataLayer = useCallback(dataLayer => {
    if (publicRuntimeConfig.gtmId) {
      TagManager.dataLayer({ dataLayer });
    }
  }, []);

  const push = useCallback(() => {
    if (valid) {
      pushDataLayer(JSON.parse(dataLayerJson));
    }
  }, [pushDataLayer, dataLayerJson, valid]);

  useEffect(() => {
    push();
  }, [push]);

  return pushDataLayer;
};

export default useDataLayer;
