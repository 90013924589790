import { useState, useEffect } from 'react';
import { throttle } from 'lodash';

import { isMobile } from 'utils/width';

interface Props {
  maxWidth?: number;
}

const useIsMobile: React.FC<Props> = (
  maxWidth = undefined,
  initialValue = false
) => {
  const [isMobileView, setIsMobileView] = useState(initialValue);

  useEffect(() => {
    const calculateIsMobileView = () => {
      if (window) {
        if (maxWidth) {
          setIsMobileView(isMobile(window.innerWidth, maxWidth));
        } else {
          setIsMobileView(isMobile(window.innerWidth));
        }
      }
    };

    calculateIsMobileView();

    const throttledCalculateIsMobileView = throttle(calculateIsMobileView, 500);

    if (window) {
      window.addEventListener('resize', throttledCalculateIsMobileView);
    }

    return () => {
      window.removeEventListener('resize', throttledCalculateIsMobileView);
    };
  }, [maxWidth]);

  return isMobileView;
};

export default useIsMobile;
